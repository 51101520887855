<div class="container-lg">
  <form>
    <div class="row">
      <div class="card mb-5 col-12">
        <div class="card-body">
          <div class="form-group form-row">
            <span class="col-12 col-md-6">
              <label for="versicherungen" class="col-form-label col-form-label-lg" translate
                >label.listeorganisationseinheiten</label
              >
            </span>
            <div class="col-12 col-md-6">
              <select
                id="versicherungen"
                name="versicherungen"
                class="form-control form-control-lg"
                [(ngModel)]="selectedVersicherung"
                (change)="selectVersicherung(selectedVersicherung)">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let versicherung of versicherungen" [ngValue]="versicherung">
                  {{ versicherung.name }}
                  {{ versicherung.authTyp === AuthTypEnum.IDP ? '(IB)' : '' }}
                </option>
              </select>
            </div>
          </div>

          <ng-container *ngIf="selectedVersicherung">
            <hr />

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label for="authTyp" class="col-form-label col-form-label-lg" translate
                  >label.authTyp</label
                >
              </span>
              <div class="col-12 col-md-6">
                <select
                  [disabled]="AuthTypEnum.IDP === selectedVersicherung.authTyp"
                  id="authTyp"
                  name="authTyp"
                  class="form-control form-control-lg"
                  [(ngModel)]="selectedVersicherung.authTyp">
                  <option
                    *ngIf="AuthTypEnum.IDP === selectedVersicherung.authTyp"
                    [value]="AuthTypEnum.IDP">
                    {{ 'enum.versicherungsauthtypenum.' + AuthTypEnum.IDP | translate }}
                  </option>
                  <!-- Only one structural directive can be applied - ngIf and ngFor must be separated ! -->
                  <ng-container *ngIf="AuthTypEnum.IDP !== selectedVersicherung.authTyp">
                    <option *ngFor="let typ of AuthTypEnumExceptSso" [value]="typ">
                      {{ 'enum.versicherungsauthtypenum.' + typ | translate }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label for="activeGsCode" class="col-form-label col-form-label-lg" translate
                  >label.gesellschaftscodeaktiv</label
                >
              </span>
              <div class="col-12 col-md-6">
                <input
                  name="activeGsCode"
                  type="text"
                  class="form-control form-control-lg"
                  id="activeGsCode"
                  autocomplete="off"
                  maxlength="3"
                  pattern="[0-9]{3}"
                  [(ngModel)]="selectedVersicherung.activeGsCode" />
              </div>
            </div>

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label for="inactiveGsCode" class="col-form-label col-form-label-lg" translate
                  >label.gesellschaftscodehistory</label
                >
              </span>
              <div class="col-12 col-md-6">
                <input
                  name="inactiveGsCode"
                  type="text"
                  class="form-control form-control-lg"
                  id="inactiveGsCode"
                  autocomplete="off"
                  pattern="([0-9]{3},?)*"
                  [(ngModel)]="gsCodeHistoryAsString" />
              </div>
            </div>

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label for="versicherungName" class="col-form-label col-form-label-lg" translate
                  >label.name</label
                >
              </span>
              <div class="col-12 col-md-6">
                <input
                  name="versicherungName"
                  type="text"
                  class="form-control form-control-lg"
                  id="versicherungName"
                  autocomplete="off"
                  maxlength="100"
                  [(ngModel)]="selectedVersicherung.name" />
              </div>
            </div>

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label for="encoding" class="col-form-label col-form-label-lg" translate
                  >label.encoding</label
                >
              </span>
              <div class="col-12 col-md-6">
                <input
                  name="encoding"
                  type="text"
                  class="form-control form-control-lg"
                  id="encoding"
                  autocomplete="off"
                  [(ngModel)]="selectedVersicherung.fileImportEncoding" />
              </div>
            </div>

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label for="mail" class="col-form-label col-form-label-lg" translate
                  >label.emailpostbox</label
                >
              </span>
              <div class="col-12 col-md-6">
                <input
                  name="mail"
                  type="email"
                  class="form-control form-control-lg"
                  id="mail"
                  maxlength="100"
                  autocomplete="off"
                  email
                  [(ngModel)]="selectedVersicherung.emailPostbox" />
              </div>
            </div>

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label for="typ" class="col-form-label col-form-label-lg" translate
                  >label.typ</label
                >
              </span>
              <div class="col-12 col-md-6">
                <select
                  id="typ"
                  name="typ"
                  class="form-control form-control-lg"
                  [(ngModel)]="selectedVersicherung.typ"
                  [compareWith]="compareFn">
                  <option [ngValue]="undefined"></option>
                  <option *ngFor="let typ of versicherungsTypen" [value]="typ">
                    {{ 'enum.versicherungstypenum.' + typ | translate }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group form-row">
              <span class="col-12 col-md-6">
                <label class="col-form-label col-form-label-lg" translate>label.status</label>
              </span>
              <div class="col-12 col-md-6">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="status"
                    id="inactive"
                    [(ngModel)]="selectedVersicherung.aktiv"
                    [value]="true" />
                  <label class="form-control-lg" for="inactive" translate
                    >booleanwrapperconverter.true</label
                  >
                </div>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="status"
                    id="active"
                    [(ngModel)]="selectedVersicherung.aktiv"
                    [value]="false" />
                  <label class="form-check-label form-control-lg" for="active" translate
                    >booleanwrapperconverter.false</label
                  >
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-6 col-md-3" *ngIf="selectedVersicherung">
                <button
                  type="button"
                  class="btn btn-primary form-control"
                  (click)="newVersicherung()"
                  translate>
                  label.neueoe
                </button>
              </div>
              <div
                class="col-6 col-md-3"
                [ngClass]="{
                  'offset-md-6': !selectedVersicherung,
                  'offset-md-3': selectedVersicherung
                }">
                <button
                  type="button"
                  class="btn btn-outline-secondary form-control"
                  (click)="cancel()"
                  translate>
                  label.abbrechen
                </button>
              </div>
              <div class="col-6 col-md-3">
                <button
                  type="submit"
                  class="btn btn-primary form-control"
                  (click)="save()"
                  translate>
                  label.speichern
                </button>
              </div>
            </div>
            <div class="form-row mt-4" *ngIf="selectedVersicherung">
              <div class="col-6 col-md-3">
                <button
                  type="button"
                  class="btn btn-outline-secondary form-control"
                  [routerLink]="['/administration-uebersicht']"
                  translate>
                  label.zurueck
                </button>
              </div>
            </div>
          </ng-container>

          <div class="form-row" *ngIf="!selectedVersicherung">
            <div class="col-6 col-md-3">
              <button
                type="button"
                class="btn btn-primary form-control"
                (click)="newVersicherung()"
                translate>
                label.neueoe
              </button>
            </div>
          </div>
          <div class="form-row mt-4" *ngIf="!selectedVersicherung">
            <div class="col-6 col-md-3">
              <button
                type="button"
                class="btn btn-outline-secondary form-control"
                [routerLink]="['/administration-uebersicht']"
                translate>
                label.zurueck
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <app-full-screen-spinner
    [working]="loading"
    [transparentForDurationMs]="50"></app-full-screen-spinner>
</div>
