<div class="container">
  <div class="row" *ngIf="benutzer">
    <div class="card offset-md-2 col-8">
      <div class="card-body">
        <h5 class="mb-4" translate>label.benutzerverwaltung</h5>
        <hr class="mb-4" />
        <form #kontoForm="ngForm" (ngSubmit)="updateBenutzer(benutzer)">
          <div class="form-group row" *ngIf="benutzer?.versicherung?.authTyp">
            <label for="authTyp" class="col-form-label col-lg-3"
              >{{ 'label.authTyp' | translate }}*</label
            >
            <div class="col-lg-9">
              <input
                disabled
                id="authTyp"
                name="authTyp"
                class="form-control form-control-sm"
                [value]="
                  'enum.versicherungsauthtypenum.' + benutzer.versicherung.authTyp | translate
                " />
            </div>
          </div>

          <div class="form-group row">
            <label for="rollen" class="col-form-label col-lg-3"
              >{{ 'label.rollen' | translate }}*</label
            >
            <div class="col-lg-9">
              <!-- let's use a different select component, that is more handy for multiple selections than the html default one. -->
              <ng-select
                class="col-form-label-sm"
                [items]="benutzer.rollen"
                appendTo="body"
                name="rollen"
                id="rollen"
                [multiple]="true"
                [readonly]="true"
                [(ngModel)]="benutzer.rollen">
              </ng-select>
            </div>
          </div>

          <div class="form-group row">
            <label for="anrede" class="col-form-label col-lg-3" translate>label.anrede</label>
            <div class="col-lg-9">
              <input
                id="anrede"
                name="anrede"
                type="text"
                value="{{ benutzer.anrede | translateWithPrefix: 'enum.anrede.' }}"
                class="form-control form-control-sm" />
            </div>
          </div>
          <div class="form-group row">
            <label for="vorname" class="col-form-label col-lg-3" translate>label.vorname</label>
            <div class="col-lg-9">
              <input
                id="vorname"
                name="vorname"
                type="text"
                value="{{ benutzer.vorname }}"
                class="form-control form-control-sm"
                [disabled]="isAuthTypeIdentityBroker" />
            </div>
          </div>
          <div class="form-group row">
            <label for="nachname" class="col-form-label col-lg-3" translate>label.name</label>
            <div class="col-lg-9">
              <input
                id="nachname"
                name="nachname"
                type="text"
                value="{{ benutzer.nachname }}"
                class="form-control form-control-sm"
                [disabled]="isAuthTypeIdentityBroker" />
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col-form-label col-lg-3" translate>label.email</label>
            <div class="col-lg-9">
              <input
                id="email"
                name="email"
                type="text"
                value="{{ benutzer.email }}"
                class="form-control form-control-sm"
                disabled />
            </div>
          </div>
          <div class="form-group row">
            <label for="telefon" class="col-form-label col-lg-3" translate>label.telefonnr</label>
            <div class="col-lg-9">
              <input
                id="telefon"
                name="telefon"
                type="text"
                [(ngModel)]="benutzer.telefonNr"
                class="form-control form-control-sm" />
            </div>
          </div>
          <div class="form-group row">
            <label for="sprache" class="col-form-label col-lg-3" translate>label.sprache</label>
            <div class="col-lg-9">
              <select
                id="sprache"
                name="sprache"
                [(ngModel)]="benutzer.sprache"
                class="form-control form-control-sm"
                required>
                <option *ngFor="let sprache of sprachen" [value]="sprache">
                  {{ 'enum.spracheenum.' + sprache | translate }}
                </option>
              </select>
            </div>
          </div>
          <hr />
          <div class="form-group row pt-3 d-flex justify-content-end">
            <div class="col-sm-3">
              <button
                type="button"
                class="btn btn-outline-secondary form-control"
                routerLink="/abfragen"
                translate>
                {{ 'label.abbrechen' }}
              </button>
            </div>
            <div class="col-sm-3">
              <button type="submit" class="btn btn-primary form-control" translate>
                {{ 'label.speichern' }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
